$(document).ready(function(){

    var submitAjaxRequest = function(e){
        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        $.ajax({
            type: method,
            url: form.prop('action'),
            data: form.serialize(),
            success: function(){
                $.publish('form.submitted',form);
            }
        });
        e.preventDefault();
    };

    //Form marked with "data-remote" attribute will submit, via Ajax
    $('form[data-remote]').on('submit', submitAjaxRequest);


    //The "data-clicked-submits-form" attribute immediately submit the form on change.
    $('*[data-clicked-submits-form]').on('change',function(){

        $(this).closest('form').submit();
    });

    $.subscribe('form.submitted', function(){
        $('.flash').fadeIn(500).delay(1000).fadeOut(500);
    });

    $('form[data-remove]').submit(function (event) {
            var x = confirm("Are you sure you want to delete?");
            if (x) {
                return true;
            }
            else {
                event.preventDefault();
                return false;
            }
        });
});