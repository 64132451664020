$(function() {
    // Setup the player to autoplay the next track
    if(typeof audiojs !== 'undefined') {
        var a = audiojs.createAll({
            trackEnded: function () {
                var next = $('.liplayer.playing').next();
                if (!next.length) next = $('.liplayer').first();
                next.click();
            }
        });

        // Load in the first track
        var audio = a[0];
        first = $('.liplayer a').attr('data-src');
        $('.liplayer').first().addClass('playing');
        audio.load(first);

        // Load in a track on click
        $('.liplayer').click(function (e) {
            e.preventDefault();
            $('.liplayer').removeClass('playing');
            $('.panel-default').removeClass('playing');

            $(this).addClass('playing');
            $(this).closest('.panel-default').addClass('playing');
            audio.load($('a', this).attr('data-src'));
            audio.play();
        });
    }

});